import React, { Component } from 'react';
import { arrayOf, bool, func, node, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../../util/dates';
import moment from 'moment';
import { FieldSingleDateInput } from '../../../components';
import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import FilterPopupForSidebar from '../BookingDateRangeFilter/FilterPopupForSidebar';
import css from './BookingSingleDateFilter.module.css';

// Get the single date query parameter name
const getDateQueryParamName = queryParamNames => {
  return typeof queryParamNames === 'string' ? queryParamNames : 'date';
};
// const getDateQueryParamName = queryParamNames => {
//   return Array.isArray(queryParamNames)
//     ? queryParamNames[0]
//     : typeof queryParamNames === 'string'
//     ? queryParamNames
//     : 'dates';
// };


// Parse query parameter, which should look like "2020-05-28"
const parseValue = value => {
  const date = value ? parseDateFromISO8601(value) : null;
  return date ? { date } : { date: null };
};

// Format date value for the query.
const formatValue = (date, queryParamName) => {
  const actualDate = date && date.date && date.date.date instanceof Date ? date.date.date : null;
  const formattedDate = actualDate ? actualDate.toISOString().split('T')[0] : null;
  return { [queryParamName]: formattedDate };
};

export class SingleDateFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: true };
    this.popupControllerRef = null;
    this.plainControllerRef = null;

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      className,
      rootClassName,
      showAsPopup,
      isDesktop,
      initialValues,
      id,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      label,
      intl,
      ...rest
    } = this.props;
    
    const dateQueryParamName = getDateQueryParamName(queryParamNames);
    const initialDate =
      initialValues && initialValues[dateQueryParamName]
        ? parseValue(initialValues[dateQueryParamName])
        : { date: null };
    const isSelected = !!initialDate.date;
    const selectedDate = isSelected ? initialDate.date : null;

    const formattedDate = isSelected ? intl.formatDate(selectedDate, { month: 'short', day: 'numeric', year: 'numeric' }) : null;

    const labelForPlain = isSelected
      ? intl.formatMessage({ id: 'SingleDateFilter.labelSelectedPlain' }, { date: formattedDate })
      : label || intl.formatMessage({ id: 'SingleDateFilter.labelPlain' });

    const labelForPopup = isSelected
      ? intl.formatMessage({ id: 'SingleDateFilter.labelSelectedPopup' }, { date: formattedDate })
      : label || intl.formatMessage({ id: 'SingleDateFilter.labelPopup' });

    const labelSelection = isSelected
      ? intl.formatMessage({ id: 'SingleDateFilter.labelSelectedPopup' }, { date: formattedDate })
      : null;

    const handleSubmit = values => {
      onSubmit(formatValue(values, dateQueryParamName));
    };

    const onClearPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onClear: () => this.popupControllerRef.onReset(null) }
        : {};

    const onCancelPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onCancel: () => this.popupControllerRef.onReset(selectedDate) }
        : {};

    const onClearPlainMaybe =
      this.plainControllerRef && this.plainControllerRef.onReset
        ? { onClear: () => this.plainControllerRef.onReset(null) }
        : {};
    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={labelForPopup}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialDate}
        {...rest}
      >
        <FieldSingleDateInput
          name="date"
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
        />
      </FilterPopup>
    ) : isDesktop ? (
      <FilterPopupForSidebar
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={label}
        labelSelection={labelSelection}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialDate}
        {...rest}
      >
        <FieldSingleDateInput
          name="date"
          initialValue={initialDate.date}  // Pass the parsed date or null directly
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
        />
      </FilterPopupForSidebar>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        labelSelection={labelSelection}
        labelSelectionSeparator=":"
        isSelected={isSelected}
        id={`${id}.plain`}
        liveEdit
        onSubmit={handleSubmit}
        {...onClearPlainMaybe}
        initialValues={initialDate}
        {...rest}
      >
        <FieldSingleDateInput
          name="date"
          controllerRef={node => {
            this.plainControllerRef = node;
          }}
        />
      </FilterPlain>
    );
  }
}

SingleDateFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

SingleDateFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  showAsPopup: bool,
  liveEdit: bool,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SingleDateFilter = injectIntl(SingleDateFilterComponent);

export default SingleDateFilter;